import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthUser from "../components/AuthUser";
import FloorTopbar from "../components/FloorTopbar";
import { useUserContext } from "../context/user_context";
import { getLiveWeatherData } from "../utils/Api";
import { intervalTime } from "../utils/constants";

const LivePage = () => {
  const [summary, setSummary] = useState([]);
  const [rht, setRht] = useState([]);
  const [smk, setSmk] = useState([]);
  const [flood, setFlood] = useState([]);
  const { selectedFactoryId } = useUserContext();
  const { user, http } = AuthUser();
  //Floor id
  const [selectedFloorId, setSelectedFloorId] = useState("");
  //Floor Name
  const [selectedCompatment, setSelectedCompartment] = useState("");

  const navigate = useNavigate();

  //get total machine summary
  const fetchTotalMachineSummary = async () => {
    getLiveWeatherData()
      .then((data) => {
        // console.log("live:",data)
        let smkTmp = [];
        let fldTmp = [];
        let rhtTmp = [];
        data.map((d, i) => {
          if (d.sensor.sensor == "SMK") {
            smkTmp.push({
              name: d.warehouse,
              temperature: d.temperature,
              is_alarm: d.is_alarm,
            });
          }
          if (d.sensor.sensor == "FLD") {
            fldTmp.push({
              name: d.warehouse,
              temperature: d.temperature,
              is_alarm: d.is_alarm,
            });
          }
          if (d.sensor.sensor == "RHT") {
            rhtTmp.push({
              name: d.warehouse,
              temperature: d.temperature,
              humidity: d.humidity,
              is_alarm: d.is_alarm,
            });
          }
          if (i == data.length - 1) {
            setSmk(smkTmp);
            setFlood(fldTmp);
            setRht(rhtTmp);
          }
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchTotalMachineSummary();
    const interval = setInterval(() => {
      fetchTotalMachineSummary();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [selectedFactoryId]);

  useEffect(() => {
    // console.log('user', user);

    if (!user) {
      navigate("/login");
    }
    return () => {};
  }, []);

  const sensors = JSON.parse(sessionStorage.getItem("sensors"));

  return (
    <div className="page-100">
      <div className="mt-1 mb-5">
        <FloorTopbar
          selectedFloorId={selectedFloorId}
          setSelectedFloorId={setSelectedFloorId}
          selectedCompatment={selectedCompatment}
          setSelectedCompartment={setSelectedCompartment}
        />
      </div>
      <div
        className="row"
        style={{
          maxWidth: "1100px",
          margin: "0 auto",
          overflow: "visible",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
        }}
      >
        {sensors?.find((s) => s?.sensor === "RHT") && (
          <span className="m-2 fw-bold">Temperature & Humidity Sensor</span>
        )}
        {rht.map((card, i) => {
          return (
            <div key={i} className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="card bg-white ps-4 pt-3 pb-3"
                style={{ borderRadius: "5px" }}
              >
                <div className="d-flex justify-content-start ">
                  <div
                    style={{
                      height: "15px",
                      width: "15px",
                      backgroundColor: "#07C17E",
                      borderRadius: "50%",
                      marginTop: "1%",
                    }}
                  ></div>
                  <span
                    style={{
                      color:
                        card.is_alarm == "false" || card.is_alarm == false
                          ? "#373F47"
                          : "#DB0909",
                      marginLeft: "5%",
                    }}
                  >
                    {card.name}
                  </span>
                </div>
                <span className="fw-bold fs-6 mt-2">Temperature</span>
                <span className="fw-bold fs-4 mt-2">
                  {card.temperature} ° C
                </span>
                <span className="fw-bold fs-6 mt-2">Relative Humidity</span>
                <span className="fw-bold fs-4 mt-2">{card.humidity} %</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-4 mb-4">
        <div
          className="row"
          style={{
            maxWidth: "1100px",
            margin: "auto",
            overflow: "visible",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          {sensors?.find((s) => s?.sensor === "SMK") && (
            <span className="m-2 fw-bold">Smoke Sensors</span>
          )}
          {smk.map((s, i) => {
            return (
              <div key={i} className="col-xl-3 col-lg-3 col-md-6">
                <div
                  className="card bg-white ps-4 pt-3 pb-3"
                  style={{ borderRadius: "5px" }}
                >
                  <div className="d-flex justify-content-start ">
                    <div
                      style={{
                        height: "15px",
                        width: "15px",
                        backgroundColor:
                          s.is_alarm == "false" || s.is_alarm == false
                            ? "#07C17E"
                            : "#DB0909",
                        borderRadius: "50%",
                        marginTop: "1%",
                      }}
                    ></div>
                    <span style={{ color: "#373F47", marginLeft: "5%" }}>
                      {s.name}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <div
            className="row"
            style={{
              maxWidth: "1100px",
              margin: "0 auto",
              overflow: "visible",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
            }}
          >
            {sensors?.find((s) => s?.sensor === "FLD") && (
              <span className="m-2 fw-bold">Flood Sensors</span>
            )}
            {flood.map((f, i) => {
              return (
                <div key={i} className="col-xl-3 col-lg-3 col-md-6">
                  <div
                    className="card bg-white ps-4 pt-3 pb-3"
                    style={{ borderRadius: "5px" }}
                  >
                    <div className="d-flex justify-content-start ">
                      <div
                        style={{
                          height: "15px",
                          width: "15px",
                          backgroundColor:
                            f.is_alarm == "false" || f.is_alarm == false
                              ? "#07C17E"
                              : "#DB0909",
                          borderRadius: "50%",
                          marginTop: "1%",
                        }}
                      ></div>
                      <span style={{ color: "#373F47", marginLeft: "5%" }}>
                        {f.name}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Wrapper = styled.section`
  font-family: var(--ff-primary);
  .clr-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.1rem;
    border: none;
    margin: auto 0;
  }

  .machine-amount {
    color: var(--clr-primary-10);
    text-align: center;
    margin: auto 0;
    padding: 5px;
    p {
      margin: 5px 0 0 0;
    }
  }
`;
export default LivePage;
