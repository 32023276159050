import { Skeleton } from "@material-ui/lab";
import React, { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import styled from "styled-components";

const TableData = ({ loading, report, graphView }) => {
  const tableRef = useRef(null);
  return (
    <Wrapper
      className={
        graphView ||
        loading ||
        report?.length === 0 ||
        report?.length === undefined
          ? "d-none"
          : ""
      }
    >
      <DownloadTableExcel
        filename="sensordata"
        sheet="sensordata"
        currentTableRef={tableRef.current}
      >
        <button className="btn mb-2 ms-1"> Export </button>
      </DownloadTableExcel>
      <div className="b-table">
        <div>
          <table className="table bg-white rounded" ref={tableRef}>
            <thead
              style={{ borderBottom: "1px solid #FAFAFA" }}
              className="text-muted"
            >
              <tr
                style={{
                  height: "50px",
                  margin: "auto",
                  verticalAlign: "middle",
                  borderBottom: "1px solid #FAFAFA",
                  borderStyle: "none",
                }}
              >
                <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                  <div className="text-center">Date</div>
                </th>
                <th scope="col  text-center" style={{ color: "#4F5B67" }}>
                  <div className="text-center">Time</div>
                </th>
                {report &&
                  report[0]?.sdata?.map((r, i) => {
                    //heading->console.log(r.sensor)
                    return (
                      <th
                        key={i}
                        scope="col text-center"
                        style={{ color: "#4F5B67" }}
                      >
                        {/* {console.log("r:",r.sensor)} */}
                        <div className="text-center">{r.sensor}</div>
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {report &&
                report?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        height: "50px",
                        margin: "auto",
                        backgroundColor:
                          item?.sdata[0]?.is_alarm == "true" ||
                          item?.sdata[0]?.is_alarm == true ||
                          item?.sdata[1]?.is_alarm == "true" ||
                          item?.sdata[1]?.is_alarm == true ||
                          item?.sdata[2]?.is_alarm == "true" ||
                          item?.sdata[2]?.is_alarm == true ||
                          item?.sdata[3]?.is_alarm == "true" ||
                          item?.sdata[3]?.is_alarm == true ||
                          item?.sdata[4]?.is_alarm == "true" ||
                          item?.sdata[4]?.is_alarm == true ||
                          item?.sdata[5]?.is_alarm == "true" ||
                          item?.sdata[5]?.is_alarm == true ||
                          item?.sdata[6]?.is_alarm == "true" ||
                          item?.sdata[6]?.is_alarm == true ||
                          item?.sdata[7]?.is_alarm == "true" ||
                          item?.sdata[7]?.is_alarm == true ||
                          item?.sdata[8]?.is_alarm == "true" ||
                          item?.sdata[8]?.is_alarm == true ||
                          item?.sdata[9]?.is_alarm == "true" ||
                          item?.sdata[9]?.is_alarm == true ||
                          item?.sdata[10]?.is_alarm == "true" ||
                          item?.sdata[10]?.is_alarm == true
                            ? "#FF7777"
                            : "white",
                      }}
                    >
                      <td className=" text-center">{item?.date}</td>
                      <td>
                        <div className="mt-2 text-center">
                          <span
                            style={{
                              backgroundColor: "#FFF9CF",
                              color: "#7B5C03",
                            }}
                            className="rounded-pill p-2"
                          >
                            {item?.time}
                          </span>
                        </div>
                      </td>
                      {item?.sdata?.map((data, i) => {
                        return (
                          <td
                            key={i}
                            style={
                              data.is_alarm == "true"
                                ? { backgroundColor: "#FFB0B0" }
                                : {}
                            }
                          >
                            <div className="mt-2 text-center">
                              {data.humidity
                                ? data.humidity
                                : data.temperature
                                ? data.temperature
                                : data.is_alarm == "true"
                                ? "alarm"
                                : "clear"}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .b-table {
    width: 100%;
    border-collapse: collapse;
    border: 3px solid var(--clr-primary-1);
    border-radius: 8px !important;
    height: 400px;
    overflow-y: auto;

    thead th {
      position: sticky;
      top: 0;
      background-color: #f1f5f8;
    }
  }
`;

export default TableData;
