import React, { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import alarmImg from "../assets/alarm.svg";
import { useUserContext } from "../context/user_context";
import { getAlarmData } from "../utils/Api";
import { getTimeDate } from "./getTimeDate";
import { Skeleton } from "@material-ui/lab";
const EnvMonitoring = () => {
 
  const { selectedFactoryId } = useUserContext();
  const [alarmData, setAlarmData] = useState([]);
  const [loadingAlram, setLoadingAlarm] = useState(false);
  // const { items, requestSort, sortConfig } = useSortableData(machines);

  //faults:
  // Pwr Dwn = E0125A
  // Yarn Brk = CAC555
  // Niddle Brk = EE874D
  const isMobileView = window.innerWidth < 900;
  useEffect(() => {
    if (selectedFactoryId) {
      setLoadingAlarm(true);
      getAlarmData(selectedFactoryId)
        .then((data) => {
          setAlarmData(data);
          setLoadingAlarm(false);
        })
        .catch((err) => {});
    }
  }, [selectedFactoryId]);
  const getOriginalTblData = () => {
    return (
      <table className="table">
        <thead style={{ backgroundColor: "#F0F0F0", border: "hidden" }}>
          <tr
            style={{ height: "70px", margin: "auto", verticalAlign: "middle" }}
          >
            <th scope="col" style={{ color: "#4F5B67" }}>
              Alarm Name
            </th>
            <th scope="col" style={{ color: "#4F5B67" }}>
              Alarm Type
            </th>
            <th scope="col" style={{ color: "#4F5B67" }}>
              Alarm Time
            </th>
            <th scope="col" style={{ color: "#4F5B67" }}>
              Location
            </th>
            <th scope="col" style={{ color: "#4F5B67" }}>
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {alarmData?.map((item) => {
            return (
              <tr key={item.alarm_name} style={{ height: "70px", margin: "auto" }}>
                <td>
                  {" "}
                  <div className="d-flex justify-content-center mt-3">
                    <img
                      src={alarmImg}
                      height={10}
                      width={10}
                      className="col-4 text-end mt-1"
                    />
                    {item.alarm_name}
                  </div>
                </td>
                <td>
                  <div className="mt-3">
                    <span
                      style={{ backgroundColor: "#F17F70", color: "white" }}
                      className="rounded-pill p-2"
                    >
                      {item.alarm_type}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="mt-3">
                    <span
                      style={{ backgroundColor: "#FFE8D7", color: "#0F5B1D" }}
                      className="rounded-pill p-2"
                    >
                      {getTimeDate(item.alarm_time).time}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="mt-3">{item.location}</div>
                </td>
                <td>
                  <div className="mt-3">
                    <AiOutlineCalendar className="text-muted me-2" />
                    {getTimeDate(item.alarm_time).date}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  if(loadingAlram){
    return <Skeleton variant="rect" width="100%" height={500} />
  }
  return (
    <div style={{ paddingRight: "1vw" }}>
      {getOriginalTblData()}
    </div>
  );
};

export default EnvMonitoring;
