import React, { useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CHANGE_SELECTED_FACTORY_ID,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "../action";
import AuthUser from '../components/AuthUser';
import { userLoginReducer } from "../reducers/user_reducer";

const initialState = {
  loading: false,
  error: null,
  selectedFactoryId: localStorage.getItem("factoryId")
    ? localStorage.getItem("factoryId")
    : sessionStorage.getItem("user") &&
      JSON.parse(sessionStorage.getItem("user")).default_factory,
};

const UserContext = React.createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userLoginReducer, initialState);

  const navigate = useNavigate();
  const {http,setToken} = AuthUser();

  const changeSelectedFactoryId = (e) => {

    const value = e.target.value;

    if (value === "logout") {
      localStorage.removeItem("factoryId");
      sessionStorage.clear();
      // navigate('/login');
      window.location.href = window.location.origin+"/login";
      dispatch({ type: USER_LOGOUT });
      toast.success("Logged Out !");
    }

    dispatch({
      type: CHANGE_SELECTED_FACTORY_ID,
      payload: value,
    });
  };

  useEffect(() => {
    localStorage.setItem("factoryId", state.selectedFactoryId);
  }, [state.selectedFactoryId]);

  const login = async (username, password) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });


      const res = await http.post(`/login`,{ username, password });

      if (res.data.status === true) {

        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: res.data,
        });
        await setToken(res.data,res.data.access_token)
        localStorage.setItem("factoryId", JSON.stringify(res.data.default_factory));
        sessionStorage.setItem("sensors", JSON.stringify(res.data.sensors));

        dispatch({
          type: CHANGE_SELECTED_FACTORY_ID,
          // payload:localStorage.getItem('factoryId') && JSON.parse(localStorage.getItem('factoryId')),
          payload: !localStorage.getItem('factoryId') ? res.data.default_factory : JSON.parse(localStorage.getItem('factoryId')),
        });
       
        // navigate("/");
        window.location.href = window.location.origin+"/live";
        toast.success("Logged In !");
      } else {
       
        navigate("/login");
        toast.error(' Wrong!');
      }
    } catch (error) {
      
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response && error.response.data ? error.response.data : error.message
    })
    toast.error('Something Went Wrong!');
    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        login,
        changeSelectedFactoryId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
