// Assuming the input format is a string
export const getTimeDate = (inputFormat) => {
  // Convert the input string to a Date object
  let inputDate = new Date(inputFormat);

  // Create an array of weekdays to match the Date object's getDay() method
  let weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Extract the weekday and format it as desired (e.g., Saturday)
  let weekday = weekdays[inputDate.getDay()];

  // Extract the day and format it as desired (e.g., 27)
  let day = inputDate.getDate();

  // Create an array of months to match the Date object's getMonth() method
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract the month and format it as desired (e.g., May)
  let month = months[inputDate.getMonth()];

  // Extract the time and format it as desired (e.g., 11:50 AM)
  let hours = inputDate.getHours();
  let minutes = inputDate.getMinutes();
  let meridiem = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  let time =
    hours + ":" + (minutes < 10 ? "0" + minutes : minutes) + " " + meridiem;


  return {
    date: weekday + ", " + day + " " + month,
    time: time
  }
};
